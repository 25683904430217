define('boot/router', ['exports', 'ember', 'boot/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.map(function () {
    this.route('home', { path: '/' });
    this.route('how-it-works');
    this.route('borrow');
    this.route('invest');
    this.route('why-invest');
    this.route('marketplace');
    this.route('about');
    this.route('login');
    this.route('register');
  });

  exports['default'] = Router;

});